import { createSlice, current } from "@reduxjs/toolkit";

export const Vistoria = createSlice({
  name: "Vistoria",
  initialState: {
    loading: false,
    loadingDetalheVistoria: false,
    loadingHistoricoVistoria: false,
    loadingRecusarVistoria: false,
    loadingEscolherOutroVistoriador: false,
    loadingCancelarVistoria: false,
    stateHistoricoVistoria: false,
    stateEscolherOutroVistoriador: false,
    stateCancelarVistoria: false,
    stateRecusarVistoria: false,
    vistoriaId: null,
    loadingDataListVistoria: {},
    listVistoria: null,
    listHistoricoVistoria: [],
    vistoria: {},
    tabMenuDetalheVistoria: 0,
  },
  reducers: {
    stateLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingDetalheVistoriaStore: (state, action) => {
      state.loadingDetalheVistoria = action.payload;
    },
    setLoadingRecusarVistoriaStore: (state, action) => {
      state.loadingRecusarVistoria = action.payload;
    },
    setLoadingCancelarVistoriaStore: (state, action) => {
      state.loadingCancelarVistoria = action.payload;
    },
    setLoadingEscolherOutroVistoriadorStore: (state, action) => {
      state.loadingEscolherOutroVistoriador = action.payload;
    },
    setHistoricoVistoriaStore: (state, action) => {
      state.loadingHistoricoVistoria = action.payload;
    },
    setModalHistoriVistoriaStore: (state, action) => {
      state.stateHistoricoVistoria = action.payload;
    },
    setModalEscolherOutroVistoriadorStore: (state, action) => {
      state.stateEscolherOutroVistoriador = action.payload;
    },
    setModalCancelarVistoriaStore: (state, action) => {
      state.stateCancelarVistoria = action.payload;
    },
    setPesquisaVistoriaStore: (state, action) => {
      if (action.payload.listaRetorno) {
        state.listVistoria = action.payload.listaRetorno;
        state.loadingDataListVistoria = action.payload.loadingDataListVistoria;
      }
    },
    setUpdateIdPrestadorVistoriaStore: (state, action) => {
      let dados = current(state.listVistoria);
      const { vistoriaId, codPrestador, nmePrestador } = action.payload;
      let retorno = dados.find((elem) => elem.vistoriaId === vistoriaId);
      retorno.codPrestador = codPrestador;
      retorno.nmePrestador = nmePrestador;
      state.listVistoria = dados;
    },
    setUpdateStatusVistoriaStore: (state, action) => {
      let dados = current(state.listVistoria);
      const { vistoriaId, codSituacaoVistoria, dscSituacaoVistoria } =
        action.payload;
      let retorno = dados.find((elem) => elem.vistoriaId === vistoriaId);
      retorno.codSituacaoVistoria = codSituacaoVistoria;
      retorno.dscSituacaoVistoria = dscSituacaoVistoria;
      state.listVistoria = dados;
    },
    setUpdateTabMenuDetalheVistoriaStore: (state, action) => {
      state.tabMenuDetalheVistoria = action.payload;
    },
    setModalRecusarVistoriaStore: (state, action) => {
      state.stateRecusarVistoria = action.payload;
    },
    getListHistoricoVistoriaStore: (state, action) => {
      state.listHistoricoVistoria = action.payload;
    },
    getDetalheVistoriaStore: (state, action) => {
      const { detalhes } = action.payload;

      if (detalhes !== undefined) {
        const { vistoriaId } = detalhes;

        if (vistoriaId !== undefined) {
          state.vistoriaId = vistoriaId;
        }

        state.vistoria = detalhes;
      }
    },
    selectIdVistoriaStore: (state, action) => {
      state.vistoriaId = action.payload;
    },
    resetVistoriaStore: (state) => {
      state.loading = false;
      state.loadingDetalheVistoria = false;
      state.loadingHistoricoVistoria = false;
      state.loadingRecusarVistoria = false;
      state.loadingEscolherOutroVistoriador = false;
      state.loadingCancelarVistoria = false;
      state.stateHistoricoVistoria = false;
      state.stateEscolherOutroVistoriador = false;
      state.stateCancelarVistoria = false;
      state.stateRecusarVistoria = false;
      state.vistoriaId = null;
      state.loadingDataListVistoria = {};
      state.listVistoria = null;
      state.listHistoricoVistoria = [];
      state.vistoria = {};
      state.tabMenuDetalheVistoria = 0;
    },
    resetConsultaVistoriaStore: (state) => {
      state.listVistoria = {};
      state.loadingDataListVistoria = {};
    },
  },
});

export const {
  setLoadingDetalheVistoriaStore,
  setLoadingRecusarVistoriaStore,
  setLoadingCancelarVistoriaStore,
  setLoadingAnexosStore,
  setLoadingEscolherOutroVistoriadorStore,
  setHistoricoVistoriaStore,
  setModalHistoriVistoriaStore,
  setModalEscolherOutroVistoriadorStore,
  setModalCancelarVistoriaStore,
  setModalAgendamentoFrutadoStore,
  setModalRecusarVistoriaStore,
  setModalAgendamentoRealizadoStore,
  setModalAgendamentoRealizadoVistoriadorStore,
  setModalDataAgendamentoStore,
  setUpdateIdPrestadorVistoriaStore,
  setPesquisaVistoriaStore,
  setUpdateStatusVistoriaStore,
  setUpdateTabMenuDetalheVistoriaStore,
  getDetalheVistoriaStore,
  getListHistoricoVistoriaStore,
  getListAgendamentoVistoriaStore,
  resetVistoriaStore,
  selectIdVistoriaStore,
  resetConsultaVistoriaStore,
} = Vistoria.actions;

export default Vistoria.reducer;
