import { createSlice } from "@reduxjs/toolkit";
export const Ajuda = createSlice({
  name: "Ajuda",
  initialState: {
    loadingAjuda: false,
    loadingListAjuda: false,
    listAjuda: [],
  },
  reducers: {
    setLoadinAjudaStore: (state, action) => {
      state.loadingAjuda = action.payload;
    },
    setLoadinListAjudaStore: (state, action) => {
      state.loadingListAjuda = action.payload;
    },
    getListHistoriAjudaVistoriaStore: (state, action) => {
      state.listAjuda = action.payload.campos;
    },
    setResetListaAjudaVistoria: (state) => {
      state.loadingAjuda = false;
      state.loadingListAjuda = false;
      state.listAjuda = [];
    },
  },
});

export const {
  setLoadinAjudaStore,
  setLoadinListAjudaStore,
  getListHistoriAjudaVistoriaStore,
  setResetListaAjudaVistoria,
} = Ajuda.actions;

export default Ajuda.reducer;
