import { useDispatch } from "react-redux";
import {
  setLoadingRecusaVistoria,
  setLoadingCancelarVistoria,
  getListPrestadorStore,
  getListStatusVistoriaStore,
  getListStatusAgendamentoStore,
  getListMotivoSolicitaAjudaStore,
  getListMotivoRecusaVistoriaStore,
  getListTiposArquivosStore,
  getListTiposConstrucaoStore,
  getListTipoObjetoSeguroStore,
  getListAtividadeEmpresarialStore,
  getListTipoSeguroStore,
  getListMotivoCancelarVistoriaStore,
  getListTipoUsuariosStore,
  getListGrupoUsuariosStore,
  getListPerfilUsuarioStore,
} from "../store/dominios.store";
import { requests } from "utils";

const DominiosActions = () => {
  const dispatch = useDispatch();
  const { findGetRequest } = requests();

  const getListStatusVistoriaAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboStatusVistoria",
    });
    if (response) {
      dispatch(getListStatusVistoriaStore(response.data.dominio));
    }
  };

  const getListPrestadorAction = async () => {
    const response = await findGetRequest({ rota: "dominios/IComboPrestador" });
    if (response) {
      dispatch(getListPrestadorStore(response.data.dominio));
    }
  };

  const getListStatusAgendamentoAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboStatusAgendamento",
    });
    if (response) {
      dispatch(getListStatusAgendamentoStore(response.data.dominio));
    }
  };

  const getListMotivoRecusaVistoriaAction = async () => {
    dispatch(setLoadingRecusaVistoria(true));
    const response = await findGetRequest({
      rota: "dominios/IComboMotivoRecusaVistoria",
    });
    if (response) {
      dispatch(getListMotivoRecusaVistoriaStore(response.data.dominio));
    }
    dispatch(setLoadingRecusaVistoria(false));
  };

  const getListMotivoSolicitaAjudaAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboMotivoSolicitaAjuda",
    });
    if (response) {
      dispatch(getListMotivoSolicitaAjudaStore(response.data.dominio));
    }
  };

  const getListTiposArquivosAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboTipoArquivo",
    });
    if (response) {
      dispatch(getListTiposArquivosStore(response.data.dominio));
    }
  };

  const getListTiposConstrucaoAction = async () => {
    const response = await findGetRequest({ rota: "dominios/ITipoConstrucao" });
    if (response) {
      dispatch(getListTiposConstrucaoStore(response.data.dominio));
    }
  };

  const getListTipoObjetoSeguroAction = async () => {
    const response = await findGetRequest({ rota: "dominios/IObjetoSeguro" });
    if (response) {
      dispatch(getListTipoObjetoSeguroStore(response.data.dominio));
    }
  };

  const getListAtividadeEmpresarialction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IAtividadeEmpresarial",
    });
    if (response) {
      dispatch(getListAtividadeEmpresarialStore(response.data.dominio));
    }
  };

  const getListTipoSeguroAction = async () => {
    const response = await findGetRequest({ rota: "dominios/ITipoSeguro" });
    if (response) {
      dispatch(getListTipoSeguroStore(response.data.dominio));
    }
  };

  const getListMotivoCancelarVistoriaAction = async () => {
    dispatch(setLoadingCancelarVistoria(true));
    const response = await findGetRequest({
      rota: "dominios/IComboMotivoCancelarVistoria",
    });
    if (response) {
      dispatch(getListMotivoCancelarVistoriaStore(response.data.dominio));
    }
    dispatch(setLoadingCancelarVistoria(false));
  };

  const getListTipoUsuariosAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboTipoUsuario",
    });
    if (response) {
      dispatch(getListTipoUsuariosStore(response.data.dominio));
    }
  };

  const getListPerfilUsuarioAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboPerfilUsuario",
    });
    if (response) {
      dispatch(getListPerfilUsuarioStore(response.data.dominio));
    }
  };

  const getListGrupoUsuariosAction = async () => {
    const response = await findGetRequest({
      rota: "dominios/IComboGrupoUsuario",
    });
    if (response) {
      dispatch(getListGrupoUsuariosStore(response.data.dominio));
    }
  };

  return {
    getListStatusVistoriaAction,
    getListStatusAgendamentoAction,
    getListPrestadorAction,
    getListMotivoRecusaVistoriaAction,
    getListMotivoSolicitaAjudaAction,
    getListTiposArquivosAction,
    getListTiposConstrucaoAction,
    getListTipoObjetoSeguroAction,
    getListAtividadeEmpresarialction,
    getListTipoSeguroAction,
    getListMotivoCancelarVistoriaAction,
    getListTipoUsuariosAction,
    getListGrupoUsuariosAction,
    getListPerfilUsuarioAction,
  };
};

export default DominiosActions;
