import { sessionsHooks } from "hooks";
import { base64File, requests } from "utils";
import { useParams } from "react-router-dom";

const DocumentsActions = () => {
  const { setPostRequest } = requests();
  const { user_id } = sessionsHooks();
  const { vistoria_id } = useParams();

  const handleSaveDocumentAction = async (files, codTipoArquivo) => {
    const responseUploadDocuments = await files.map(async (file) => {
      let documentName = file.name;
      const extenssao = documentName.split(".").pop();

      await base64File(file).then(async (res) => {
        const fileBase64 = res.split(";")[1].replace("base64,", "");

        const document = {
          codUsuarioLogado: user_id,
          codTipoArquivo: codTipoArquivo,
          nmeArquivo: documentName,
          extensaoArquivo: extenssao,
          conteudoArquvio: fileBase64,
        };

        await setPostRequest({
          rota: `/vistoria/${vistoria_id}/enviarArquivo`,
          params: document,
          message: "Upload realizado com sucesso!",
        });
      });
    });

    return Promise.all(responseUploadDocuments).then(() => {
      return files;
    });
  };

  return {
    handleSaveDocumentAction,
  };
};

export default DocumentsActions;
