import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "./assets/fontawesome/css/all.min.css";
import "./assets/scss/App.scss";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import { AppRouter } from "./AppRouter";

const App = () => {
  useEffect(() => {
    renderTemplateLayout();
  }, []);

  const renderTemplateLayout = () => {
    const root = document.documentElement;
    const colorRoxo1 = "#2d2c7c";
    const colorRoxo2 = "#5E5E9B";
    const colorVerde = "#45B97C";
    const colorWhite = "#fff";
    const colorAzulClaro = "#006ad0";

    root.style.setProperty("--background-color-Dark-button", colorRoxo1);
    root.style.setProperty("--background-color-light-button", colorAzulClaro);
    root.style.setProperty("--background-menu-root", colorRoxo1);
    root.style.setProperty("--background-menu-root-hover", colorRoxo2);
    root.style.setProperty("--background-menu-root-active", colorRoxo2);
    root.style.setProperty("--color-font-menu-root", colorWhite);
    root.style.setProperty("--color-font-menu-root-hover", colorWhite);
    root.style.setProperty("--color-font-sub-menu-root", colorWhite);
    root.style.setProperty("--color-5", colorWhite);
    root.style.setProperty("--color-6", colorWhite);
    root.style.setProperty("--color-8", colorRoxo1);
    root.style.setProperty("--color-9", colorRoxo1);
    root.style.setProperty("--color-16", colorRoxo1);

    root.style.setProperty("--background-header-user", colorVerde);
    root.style.setProperty("--text-header-user", colorWhite);
  };

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
