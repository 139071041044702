import { useSelector } from "react-redux";

const DominiosHooks = () => {
  const {
    loadingRecusaVistoria,
    loadingCancelarVistorias,
    stateAgendamentoFrustado,
    listPrestador,
    listStatusVistoria,
    listStatusAgendamento,
    listMotivoRecusaVistoria,
    listMotivoSolicitaAjuda,
    listTiposArquivos,
    listTiposConstrucao,
    listTipoObjetoSeguro,
    listAtividadeEmpresarial,
    listTipoSeguro,
    listCancelarVistoria,
    listTipoUsuarios,
    listGrupoUsuarios,
    listPerfilUsuarios,
  } = useSelector((state) => state.dominios);

  return {
    loadingRecusaVistoria,
    loadingCancelarVistorias,
    stateAgendamentoFrustado,
    listPrestador,
    listStatusVistoria,
    listStatusAgendamento,
    listMotivoRecusaVistoria,
    listMotivoSolicitaAjuda,
    listTiposArquivos,
    listTiposConstrucao,
    listTipoObjetoSeguro,
    listAtividadeEmpresarial,
    listTipoSeguro,
    listCancelarVistoria,
    listTipoUsuarios,
    listGrupoUsuarios,
    listPerfilUsuarios,
  };
};

export default DominiosHooks;
