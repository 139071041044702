import { useSelector } from "react-redux";

const UsuariosHooks = () => {
  const { listUsuarios, usuario, loadingDataListUsuarios } = useSelector(
    (state) => state.usuarios
  );

  return { listUsuarios, usuario, loadingDataListUsuarios };
};

export default UsuariosHooks;
