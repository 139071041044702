import axios from "axios";
import { keycloak } from "../utils/auth";
import { configUrlsAmbiente } from "./configUrlsAmbiente";
const configUrl = configUrlsAmbiente();

let ApiVistoria = axios.create({
  baseURL: configUrl.REACT_APP_API_VISTORIA,
});
ApiVistoria.defaults.headers["Access-Control-Allow-Origin"] = "*";

ApiVistoria.interceptors.request.use((config) => {
  const token = keycloak.token;

  // const cb = () => {
  config.headers.Authorization = `Bearer ${token}`;
  // return Promise.resolve(config);
  // };
  keycloak.updateToken();
  return config;
});

export { ApiVistoria };
