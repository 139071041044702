import { createSlice } from "@reduxjs/toolkit";
export const Dominios = createSlice({
  name: "Dominios",
  initialState: {
    loadingRecusaVistoria: false,
    loadingCancelarVistorias: false,
    listMotivoRecusaVistoria: null,
    listPrestador: [],
    listStatusVistoria: [],
    listStatusAgendamento: [],
    listMotivoSolicitaAjuda: [],
    listTiposArquivos: [],
    listTiposConstrucao: [],
    listTipoObjetoSeguro: [],
    listAtividadeEmpresarial: [],
    listTipoSeguro: [],
    listCancelarVistoria: [],
    listTipoUsuarios: [],
    listGrupoUsuarios: [],
    listPerfilUsuarios: [],
  },
  reducers: {
    setLoadingRecusaVistoria: (state, action) => {
      state.loadingRecusaVistoria = action.payload;
    },
    setLoadingCancelarVistoria: (state, action) => {
      state.loadingCancelarVistorias = action.payload;
    },
    getListPrestadorStore: (state, action) => {
      state.listPrestador = action.payload;
    },
    getListStatusVistoriaStore: (state, action) => {
      state.listStatusVistoria = action.payload;
    },
    getListStatusAgendamentoStore: (state, action) => {
      state.listStatusAgendamento = action.payload;
    },
    getListMotivoRecusaVistoriaStore: (state, action) => {
      state.listMotivoRecusaVistoria = action.payload;
    },
    getListMotivoSolicitaAjudaStore: (state, action) => {
      state.listMotivoSolicitaAjuda = action.payload;
    },
    getListTiposArquivosStore: (state, action) => {
      state.listTiposArquivos = action.payload;
    },
    getListTiposConstrucaoStore: (state, action) => {
      state.listTiposConstrucao = action.payload;
    },
    getListTipoObjetoSeguroStore: (state, action) => {
      state.listTipoObjetoSeguro = action.payload;
    },
    getListAtividadeEmpresarialStore: (state, action) => {
      state.listAtividadeEmpresarial = action.payload;
    },
    getListTipoSeguroStore: (state, action) => {
      state.listTipoSeguro = action.payload;
    },
    getListMotivoCancelarVistoriaStore: (state, action) => {
      state.listCancelarVistoria = action.payload;
    },
    getListTipoUsuariosStore: (state, action) => {
      state.listTipoUsuarios = action.payload;
    },
    getListGrupoUsuariosStore: (state, action) => {
      state.listGrupoUsuarios = action.payload;
    },
    getListPerfilUsuarioStore: (state, action) => {
      state.listPerfilUsuarios = action.payload;
    },
  },
});

export const {
  setLoadingRecusaVistoria,
  setLoadingCancelarVistoria,
  getListPrestadorStore,
  getListStatusVistoriaStore,
  getListStatusAgendamentoStore,
  getListMotivoRecusaVistoriaStore,
  getListMotivoSolicitaAjudaStore,
  getListTiposArquivosStore,
  getListTiposConstrucaoStore,
  getListTipoObjetoSeguroStore,
  getListAtividadeEmpresarialStore,
  getListTipoSeguroStore,
  getListMotivoCancelarVistoriaStore,
  getListTipoUsuariosStore,
  getListGrupoUsuariosStore,
  getListPerfilUsuarioStore,
} = Dominios.actions;

export default Dominios.reducer;
