import { useSelector } from "react-redux";

const AjudaHooks = () => {
  const { loadingAjuda, loadingListAjuda, listAjuda } = useSelector(
    (state) => state.ajuda
  );

  return { loadingAjuda, loadingListAjuda, listAjuda };
};

export default AjudaHooks;
