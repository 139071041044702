import { createSlice } from "@reduxjs/toolkit";
export const Usuarios = createSlice({
  name: "Usuarios",
  initialState: {
    listUsuarios: null,
    usuario: {},
    loadingDataListUsuarios: {},
  },
  reducers: {
    getListUsuarioStore: (state, action) => {
      state.listUsuarios = action.payload.listUsuarios;
      state.loadingDataListUsuarios = action.payload.loadingDataListUsuarios;
    },
    getDataUsuarioStore: (state, action) => {
      state.usuario = action.payload;
    },
  },
});

export const { getListUsuarioStore, getDataUsuarioStore } = Usuarios.actions;

export default Usuarios.reducer;
