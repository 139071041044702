import { createSlice } from "@reduxjs/toolkit";
export const FormularioAgendamentoRealizado = createSlice({
  name: "FormularioAgendamentoRealizado",
  initialState: {
    step: 0,
    dadosDoSegurado: {},
    atidade: {},
    construcao: {},
    sistemasProtecionais: {},
    informacoesAdicionais: {},
    AnexarLaudosArquivos: {},
  },
  reducers: {
    setdadosDoSegurado: (state, action) => {
      state.dadosDoSegurado = action.payload;
    },
  },
});

export const { setdadosDoSegurado } = FormularioAgendamentoRealizado.actions;

export default FormularioAgendamentoRealizado.reducer;
