import { useDispatch } from "react-redux";
import { requests } from "utils";
import {
  setResetFormularioStore,
  getFormulariosStore,
} from "../store/formularios.store";
import { setloadingPageStore } from "../store/global.store";

const FormulariosActions = () => {
  const dispatch = useDispatch();
  const { findGetRequest } = requests();

  const getFormulariosAction = async (idFormulario) => {
    dispatch(setloadingPageStore(true));
    dispatch(setResetFormularioStore());
    const response = await findGetRequest({
      rota: `formularios/${idFormulario}`,
    });

    if (response) {
      dispatch(getFormulariosStore(response.data.agrupadores));
    }

    dispatch(setloadingPageStore(false));
  };

  return { getFormulariosAction };
};

export default FormulariosActions;
