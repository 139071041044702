import React from "react";

import "./_buttonStickBottom.scss";

const ButtonStickBottom = (props) => {
  const { className, children } = props;
  return <div className={`buttonStickBottom ${className}`}>{children}</div>;
};

ButtonStickBottom.defaultProps = { className: "" };

export default ButtonStickBottom;
