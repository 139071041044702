export const UFS = [
  { sigla: "AC" },
  { sigla: "AL" },
  { sigla: "AP" },
  { sigla: "AM" },
  { sigla: "BA" },
  { sigla: "CE" },
  { sigla: "DF" },
  { sigla: "ES" },
  { sigla: "GO" },
  { sigla: "MA" },
  { sigla: "MT" },
  { sigla: "MS" },
  { sigla: "MG" },
  { sigla: "PA" },
  { sigla: "PB" },
  { sigla: "PR" },
  { sigla: "PE" },
  { sigla: "PI" },
  { sigla: "RJ" },
  { sigla: "RN" },
  { sigla: "RS" },
  { sigla: "RO" },
  { sigla: "RR" },
  { sigla: "SC" },
  { sigla: "SP" },
  { sigla: "SE" },
  { sigla: "TO" },
];

export const statusSistema = {
  vistoriaSolicitada: 1,
  vistoriaAceitaPrestador: 2,
  vistoriaRecusadaPrestador: 3,
  vistoriaDispensada: 4,
  vistoriaAgendada: 5,
  vistoriaAgendamentoRealizada: 6,
  vistoriaFinalizada: 7,
  vistoriaCancelada: 8,
  vistoriaLaudoEnviado: 9,
};

export const statusAgendamento = {
  agendamentoNovo: 1,
  agendamentoRealizado: 2,
  agendamentoFrustado: 3,
  agendamentoCancelado: 4,
};

///Administrado e header terá a mesma funçao de subscritor

export const configTipoUsuario = {
  administrador: 1,
  subscritor: 2,
  corretor: 3,
  negocial: 4,
  funcionario: 5,
  grupo: 6,
  heade: 7,
  prestador: 8,
};

export const configRoutes = {
  vistoria: {
    consultar: {
      rota: "/consultar-vistoria",
      permissao: [
        configTipoUsuario.prestador,
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    cadastrar: {
      rota: "/cadastrar-vistoria",
      permissao: [
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },

    detalhe: {
      rota: "/detalhe-vistoria",
      permissao: [
        configTipoUsuario.prestador,
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    relatorio: {
      rota: "/relatorio-vistoria",
      permissao: [
        configTipoUsuario.prestador,
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    agendamentorealizado: {
      rota: "/agendamentorealizado-vistoria",
      permissao: [
        configTipoUsuario.prestador,
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    agendamentorealizadoVerFormulario: {
      rota: "/vistoria-ver-formulario",
      permissao: [
        configTipoUsuario.prestador,
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    anexos: {
      rota: "/anexos-vistoria",
      permissao: [
        configTipoUsuario.prestador,
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
  },
  administracao: {
    permissao: [
      configTipoUsuario.subscritor,
      configTipoUsuario.administrador,
      configTipoUsuario.heade,
    ],
  },
  usuarios: {
    consultar: {
      rota: "/consultar-usuarios",
      permissao: [
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    cadastrar: {
      rota: "/cadastrar-usuarios",
      permissao: [
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
    editar: {
      rota: "/editar-usuarios",
      permissao: [
        configTipoUsuario.subscritor,
        configTipoUsuario.administrador,
        configTipoUsuario.heade,
      ],
    },
  },
};

export const menuList = () => {
  return [
    {
      nome: "HOME",
      icone: "fa-solid fa-house",
      url: "/cotador",
      permissao: configRoutes.vistoria.consultar.permissao,
    },
    {
      nome: "VISTORIA",
      icone: "fa-solid fa-list-check",
      permissao: configRoutes.vistoria.consultar.permissao,
      submenuItems: [
        {
          nome: "Cadastrar Vistoria Manual",
          url: configRoutes.vistoria.cadastrar.rota,
          permissao: configRoutes.vistoria.cadastrar.permissao,
        },
        {
          nome: "Consultar Vistoria",
          url: configRoutes.vistoria.consultar.rota,
          permissao: configRoutes.vistoria.consultar.permissao,
        },
        {
          nome: "Relatório",
          url: configRoutes.vistoria.relatorio.rota,
          permissao: configRoutes.vistoria.relatorio.permissao,
        },
      ],
    },
    {
      nome: "ADMINISTRAÇÃO",
      icone: "fa-solid fa-gear",
      permissao: configRoutes.administracao.permissao,
      submenuItems: [
        {
          nome: "USUÁRIOS",
          permissao: configRoutes.usuarios.consultar.permissao,
          submenuItems: [
            {
              nome: "CONSULTAR",
              url: configRoutes.usuarios.consultar.rota,
              permissao: configRoutes.usuarios.consultar.permissao,
            },
            {
              nome: "CADASTRAR",
              url: configRoutes.usuarios.cadastrar.rota,
              permissao: configRoutes.usuarios.cadastrar.permissao,
            },
          ],
        },
      ],
    },
  ];
};

export const messageValidation = (message = null) => {
  return message
    ? `${message} é de preenchimento obrigatório`
    : "Campo obrigatório";
};
