import { createSlice } from "@reduxjs/toolkit";
import { getGenerateDataForm, getGenerateValueForm } from "utils";
export const Formularios = createSlice({
  name: "Formularios",
  initialState: {
    loadingFormularios: false,
    stepsFormularios: 0,
    getFormularios: {},
    getFormularioVistoria: {},
    IDadosConstrucao: {},
    IDadosAtividade: {},
    IDadosSegurado: {},
    IInformacoesAdicionais: {},
    ISistemasProtecionais: {},
    getDataFormularioVistoria: {},
  },
  reducers: {
    setLoadingFormulariosStore: (state, action) => {
      state.loadingFormularios = action.payload;
    },
    getFormulariosStore: (state, action) => {
      const {
        IDadosConstrucao,
        IDadosAtividade,
        IDadosSegurado,
        ISistemasProtecionais,
        IInformacoesAdicionais,
      } = action.payload;
      let newIDadosSegurado = getGenerateDataForm(IDadosSegurado.campos);
      let newIDadosAtividade = getGenerateDataForm(IDadosAtividade.campos);
      let newIDadosConstrucao = getGenerateDataForm(IDadosConstrucao.campos);
      let newISistemasProtecionais = getGenerateDataForm(
        ISistemasProtecionais.campos
      );
      let newIInformacoesAdicionais = getGenerateDataForm(
        IInformacoesAdicionais.campos
      );

      state.getFormularios = action.payload;
      state.IDadosSegurado = newIDadosSegurado;
      state.IDadosAtividade = newIDadosAtividade;
      state.IDadosConstrucao = newIDadosConstrucao;
      state.ISistemasProtecionais = newISistemasProtecionais;
      state.IInformacoesAdicionais = newIInformacoesAdicionais;
    },
    getFormulariosVistoriaStore: (state, action) => {
      const { campos } = action.payload;

      let getDataFormularioVistoria = getGenerateValueForm(campos);
      state.getFormularioVistoria = action.payload;
      state.getDataFormularioVistoria = getDataFormularioVistoria;
    },
    setResetFormularioStore: (state) => {
      state.loadingFormularios = false;
      state.stepsFormularios = 0;
      state.getFormularios = {};
      state.getFormularioVistoria = {};
      state.IDadosConstrucao = {};
      state.IDadosAtividade = {};
      state.IDadosSegurado = {};
      state.IInformacoesAdicionais = {};
      state.ISistemasProtecionais = {};
    },
    incrementStepsVistoriaStore: (state) => {
      const steps = state.stepsFormularios;
      state.stepsFormularios = steps + 1;
    },
    decrementStepsVistoriaStore: (state) => {
      const steps = state.stepsFormularios;
      state.stepsFormularios = steps - 1;
    },
  },
});

export const {
  setLoadingFormulariosStore,
  getFormulariosStore,
  getFormulariosVistoriaStore,
  getPlantaStore,
  incrementStepsVistoriaStore,
  decrementStepsVistoriaStore,
  setResetFormularioStore,
} = Formularios.actions;

export default Formularios.reducer;
