import { requests } from "utils";
import { ApiCep } from "api";
import { useDispatch } from "react-redux";
import { setloadingPageStore } from "../store/global.store";

const CepActions = () => {
  const dispatch = useDispatch();
  const { findGetRequest } = requests();

  const getByCep = async (cep) => {
    dispatch(setloadingPageStore(true));
    const response = await findGetRequest({ rota: `${cep}/json`, Api: ApiCep });
    dispatch(setloadingPageStore(false));
    return response;
  };

  return { getByCep };
};

export default CepActions;
