import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setPesquisaVistoriaStore,
  setModalCancelarVistoriaStore,
  setModalEscolherOutroVistoriadorStore,
  setModalHistoriVistoriaStore,
  setModalRecusarVistoriaStore,
  setHistoricoVistoriaStore,
  selectIdVistoriaStore,
  getDetalheVistoriaStore,
  getListHistoricoVistoriaStore,
  setUpdateIdPrestadorVistoriaStore,
  setUpdateStatusVistoriaStore,
  setUpdateTabMenuDetalheVistoriaStore,
  resetVistoriaStore,
} from "../../store/vistoria.store";
import { setloadingPageStore } from "../../store/global.store";
import {
  getFormulariosVistoriaStore,
  incrementStepsVistoriaStore,
} from "../../store/formularios.store";
import { vistoriaHooks, sessionsHooks } from "hooks";
import { requests } from "utils";
import { configRoutes, configTipoUsuario } from "commons";

const VistoriaAction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    stateCancelarVistoria,
    stateEscolherOutroVistoriador,
    stateHistoricoVistoria,
    stateRecusarVistoria,
    vistoriaId,
  } = vistoriaHooks();

  const { user_id, cod_TipoUsuario } = sessionsHooks();
  const { vistoria_id } = useParams();
  const { setPostRequest, setPutRequest, setAlertRequest, findGetRequest } =
    requests();

  const setRouteCotador = (url) => {
    dispatch(setloadingPageStore(true));
    dispatch(resetVistoriaStore());
    window.open(url, "_self");
  };

  const setModalCancelarVistoria = (idVistoria = null, action) => {
    dispatch(setModalCancelarVistoriaStore(!stateCancelarVistoria));
    dispatch(selectIdVistoriaStore(idVistoria));
  };

  const setModalEscolherOutroVistoriador = (idVistoria = null, action) => {
    dispatch(
      setModalEscolherOutroVistoriadorStore(!stateEscolherOutroVistoriador)
    );

    dispatch(selectIdVistoriaStore(idVistoria));
  };

  const setModalHistoricoVistoria = () => {
    dispatch(setModalHistoriVistoriaStore(!stateHistoricoVistoria));
  };

  const setModalRecusarVistoriaAction = () => {
    dispatch(setModalRecusarVistoriaStore(!stateRecusarVistoria));
  };

  const setPesquisaVistoriaAction = async (dados) => {
    dados.codUsuarioLogado = user_id;
    dispatch(setloadingPageStore(true));
    const response = await setPostRequest({
      rota: "vistoria/listar",
      params: dados,
    });
    if (response) {
      const { filtro, totalRegistros } = response.data;

      dados.numeroLinhasPorPagina = filtro.numeroLinhasPorPagina;
      dados.pagAtual = filtro.pagAtual;
      dados.pagFinal = filtro.pagFinal;
      dados.totalRegistros = totalRegistros;

      dispatch(
        setPesquisaVistoriaStore({
          listaRetorno: response.data.listaRetorno,
          loadingDataListVistoria: dados,
        })
      );
    }
    dispatch(setloadingPageStore(false));
  };

  const getDetalheVistoriaAction = async (vistoriaId) => {
    dispatch(resetVistoriaStore({}));
    dispatch(setloadingPageStore(true));
    const response = await findGetRequest({ rota: `vistoria/${vistoriaId}` })
      .then((response) => {
        const { codPrestador } = response.data;

        if (
          configTipoUsuario.prestador === cod_TipoUsuario &&
          codPrestador !== user_id
        ) {
          setAlertRequest({
            code: 500,
            message: "Você não tem permissão para acessar esta vistoria.",
          });

          return navigate(`${configRoutes.vistoria.consultar.rota}`);
        }

        dispatch(getDetalheVistoriaStore({ detalhes: response.data }));
        dispatch(selectIdVistoriaStore(vistoriaId));
        setUpdateTabMenuDetalheVistoriaAction(0);
      })
      .catch(async () => {
        dispatch(getDetalheVistoriaStore({}));
      })
      .finally(() => {
        dispatch(setloadingPageStore(false));
      });
    return response;
  };

  const setChangeStateVistoriaAction = async (dados) => {
    dados.codUsuarioLogado = user_id;
    const id = vistoria_id !== undefined ? vistoria_id : vistoriaId;

    dispatch(setloadingPageStore(true));
    const response = await setPutRequest({
      rota: `vistoria/${id}`,
      params: dados,
      message: dados.message,
    });

    if (response) {
      if (vistoria_id === undefined) {
        dispatch(setUpdateStatusVistoriaStore(response.data));
      }

      if (vistoria_id !== undefined) {
        getDetalheVistoriaAction(id);
      }
    }

    dispatch(setloadingPageStore(false));
  };

  const setCustomVistoriaAction = async (dados) => {
    dados.codUsuarioLogado = user_id;

    dispatch(setloadingPageStore(true));
    const response = await setPutRequest({
      rota: `vistoria/${vistoria_id}/custo-vistoria`,
      params: dados,
      message: "CUSTO DA VISTORIA ENVIADO COM SUCESSO!",
    });
    if (response) {
      getDetalheVistoriaAction(vistoria_id);
    }

    dispatch(setloadingPageStore(false));
  };

  const setEscolherOutroVistoriadorAction = async (dados) => {
    dispatch(setloadingPageStore(true));
    dados.codUsuarioLogado = user_id;
    const response = await setPutRequest({
      rota: `vistoria/${vistoriaId}/alterar-prestador`,
      params: dados,
      message: "PRESTADOR TROCADO!",
    });
    if (response) {
      setModalEscolherOutroVistoriador();
      if (vistoria_id === undefined) {
        dispatch(setUpdateIdPrestadorVistoriaStore(response.data));
      }
      if (vistoria_id !== undefined) {
        getDetalheVistoriaAction(vistoriaId);
      }
    }

    dispatch(setloadingPageStore(false));
  };

  const setInclusaoVistoriaManualAction = async (dados) => {
    dispatch(setloadingPageStore(true));

    let newData = {
      codProduto: dados.codProduto,
      idcTipoPessoaSegurado: dados.idcTipoPessoaSegurado,
      numCPFCPNJSegurado: dados.numCPFCPNJSegurado,
      nmeSegurado: dados.nmeSegurado,
      numTelefoneSegurado: dados.numTelefoneSegurado,
      dscEmailSegurado: dados.dscEmailSegurado,
      endereco: {
        numCep: dados.numCep,
        idcTipoLogradouro: dados.idcTipoLogradouro,
        dsLogradouro: dados.dsLogradouro,
        numLogradouro: dados.numLogradouro,
        dscComplemento: dados.dscComplemento,
        dscBairro: dados.dscBairro,
        dscMunicipio: dados.dscMunicipio,
        sigUF: dados.sigUF,
        nmeResponsavel: dados.nmeResponsavel,
        numTelefoneResponsavel: dados.numTelefoneResponsavel,
        numTelefoneResponsavel2: dados.numTelefoneResponsavel2,
        dscCargoResponsavel: dados.dscCargoResponsavel,
      },
      item: {
        codTipoSeguro: dados.codTipoSeguro,
        dscTipoSeguro: dados.dscTipoSeguro,
        codAtividade: dados.codAtividade,
        dscAtividade: dados.dscAtividade,
        codConstrucao: dados.codConstrucao,
        dscConstrucao: dados.dscConstrucao,
        codObjSeguro: dados.codObjSeguro,
        dscObjSeguro: dados.dscObjSeguro,
        valorEmRisco: dados.valorEmRisco,
      },
    };

    const response = await setPostRequest({
      rota: `vistoria/inclusao-manual`,
      params: newData,
      message: "VISTORIA INCLUÍDA COM SUCESSO!",
    });

    dispatch(setloadingPageStore(false));
    return response;
  };

  const setFormulariosVistoriaAction = async (dados, limit = true) => {
    dados.codUsuarioLogado = user_id;
    dispatch(setloadingPageStore(true));
    const response = await setPutRequest({
      rota: `vistoria/${vistoria_id}/formulario`,
      params: dados,
      message: dados.message,
    });
    dispatch(setloadingPageStore(false));
    if (limit && response) {
      dispatch(incrementStepsVistoriaStore());
    }
  };

  const setRelatorioVistoriaAction = async (dados) => {
    dados.codUsuarioLogado = user_id;
    dispatch(setloadingPageStore(true));
    const response = await setPostRequest({
      rota: `/relatorioGerencial`,
      params: dados,
    });
    if (response) {
      const byteArray = new Uint8Array(response.data.relatorio);
      const blob = new Blob([byteArray], {
        type: "application/vnd.ms-excel",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `relatorio-gerencial-${dados.dataInicial}-${dados.dataFinal}.xls`
      );
      link.setAttribute("charset", "UTF-8");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    }
    dispatch(setloadingPageStore(false));
  };

  const getListHistoricoVistoriaAction = async (vistoriaId) => {
    dispatch(setHistoricoVistoriaStore(true));

    if (vistoriaId) {
      const response = await findGetRequest({
        rota: `vistoria/${vistoriaId}/historico`,
      });

      if (response) {
        dispatch(getListHistoricoVistoriaStore(response.data.historico));
      }
      dispatch(setHistoricoVistoriaStore(false));
      return response;
    }
  };

  const getFormularioVistoriaAction = async () => {
    dispatch(setloadingPageStore(true));
    const response = await findGetRequest({
      rota: `vistoria/${vistoria_id}/formulario/1`,
    });
    if (response) {
      dispatch(getFormulariosVistoriaStore(response.data));
    }
    dispatch(setloadingPageStore(false));
  };

  const setUpdateTabMenuDetalheVistoriaAction = async (index) => {
    dispatch(setUpdateTabMenuDetalheVistoriaStore(index));
  };

  return {
    getDetalheVistoriaAction,
    getListHistoricoVistoriaAction,
    getFormularioVistoriaAction,
    setPesquisaVistoriaAction,
    setEscolherOutroVistoriadorAction,
    setInclusaoVistoriaManualAction,
    setFormulariosVistoriaAction,
    setRelatorioVistoriaAction,
    setChangeStateVistoriaAction,
    setCustomVistoriaAction,
    setModalCancelarVistoria,
    setModalEscolherOutroVistoriador,
    setModalHistoricoVistoria,
    setModalRecusarVistoriaAction,
    setUpdateTabMenuDetalheVistoriaAction,
    setRouteCotador,
  };
};

export default VistoriaAction;
