import React from "react";
import "./Footer.module.scss";
const Footer = () => {
  return (
    <footer>
      <div>
        ©2022 EBIX LATIN AMERICA, TODOS OS DIREITOS RESERVADOS. DOCUMENTAÇÃO
        CONFIDENCIAL E DE PROPRIEDADE INTELECTUAL DA EBIX - REPRODUÇÃO PROIBIDA
      </div>
    </footer>
  );
};

export default Footer;
