import { useSelector } from "react-redux";

const FormulariosHooks = () => {
  const {
    loadingFormularios,
    getFormularios,
    getFormularioVistoria,
    stepsFormularios,
    IDadosConstrucao,
    IDadosAtividade,
    IDadosSegurado,
    IInformacoesAdicionais,
    ISistemasProtecionais,
    getDataFormularioVistoria,
  } = useSelector((state) => state.formularios);

  return {
    loadingFormularios,
    getFormularios,
    getFormularioVistoria,
    stepsFormularios,
    IDadosConstrucao,
    IDadosAtividade,
    IDadosSegurado,
    IInformacoesAdicionais,
    ISistemasProtecionais,
    getDataFormularioVistoria,
  };
};

export default FormulariosHooks;
