import { createSlice } from "@reduxjs/toolkit";

export const Global = createSlice({
  name: "Global",
  initialState: {
    stateAlerts: false,
    loadingPage: false,
    severity: "error",
    message: "",
  },
  reducers: {
    setStateAlerts: (state, action) => {
      let { code, stateAlerts, message } = action.payload;
      let severity;
      switch (code) {
        case 200:
          severity = "success";
          break;
        case 201:
          severity = "success";
          break;

        case 404:
          severity = "warn";
          message = "NENHUM REGISTRO FOI ENCONTRADO.";
          break;

        default:
          severity = "error";
          break;
      }

      if (message === null || message === "") {
        stateAlerts = false;
      }

      state.stateAlerts = stateAlerts;
      state.message = message;
      state.severity = severity;
    },
    setloadingPageStore: (state, action) => {
      state.loadingPage = action.payload;
    },
  },
});

export const { setStateAlerts, setloadingPageStore } = Global.actions;

export default Global.reducer;
