import { useSelector } from "react-redux";

const SessionHooks = () => {
  const { user_id, nomeUsuario, cod_TipoUsuario, flag_ativo } = useSelector(
    (state) => state.session
  );

  return {
    user_id,
    nomeUsuario,
    cod_TipoUsuario,
    flag_ativo,
  };
};

export default SessionHooks;
