import { useSelector } from "react-redux";

const AgendamentoHooks = () => {
  const {
    loadingListAgendamento,
    loadingListAgendamentoFrustado,
    stateAgendamentoFrustado,
    stateAgendamentoRealizado,
    stateDataAgendamento,
    stateVisualizarAgendamento,
    listMotivoAgendamentoFrustrado,
    listAgendamento,
    agendamentoId,
    agendamento,
  } = useSelector((state) => state.agendamento);

  return {
    loadingListAgendamento,
    loadingListAgendamentoFrustado,
    stateAgendamentoFrustado,
    stateAgendamentoRealizado,
    stateDataAgendamento,
    stateVisualizarAgendamento,
    listMotivoAgendamentoFrustrado,
    listAgendamento,
    agendamentoId,
    agendamento,
  };
};

export default AgendamentoHooks;
