import { createSlice } from "@reduxjs/toolkit";

export const Session = createSlice({
  name: "Session",
  initialState: {
    user_id: null,
    nomeUsuario: null,
    cod_TipoUsuario: null,
    flag_ativo: null,
  },
  reducers: {
    setSession: (state, action) => {
      state.user_id = parseInt(action.payload.user_id);
      state.nomeUsuario = action.payload.nomeUsuario;
      state.cod_TipoUsuario = parseInt(action.payload.cod_TipoUsuario);
      state.flag_ativo = action.payload.flag_ativo;
    },
    setLogoutSession: (state) => {
      state.user_id = null;
      state.nomeUsuario = null;
      state.cod_TipoUsuario = null;
    },
  },
});

export const { setSession, setLogoutSession } = Session.actions;

export default Session.reducer;
