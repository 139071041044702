import axios from "axios";
import { keycloak } from "../utils/auth";
import { configUrlsAmbiente } from "./configUrlsAmbiente";
const configUrl = configUrlsAmbiente();

let ApiCotador = axios.create({
  baseURL: configUrl.REACT_APP_API_COTADOR,
});

ApiCotador.defaults.headers["Access-Control-Allow-Origin"] = "*";
ApiCotador.interceptors.request.use((config) => {
  const token = keycloak.token;
  config.headers.Authorization = `Bearer ${token}`;
  keycloak.updateToken();
  return config;
});

export { ApiCotador };
