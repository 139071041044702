import { setFormatDate } from "utils";

export const filterValueForm = (lista, valueInit, valueEnd) => {
  return lista.filter((_, index) => index >= valueInit && index <= valueEnd);
};

export const selectValueForm = (lista, values) => {
  if (lista.campos.length > 0) {
    Object.entries(values).forEach((elem) => {
      const [idCampo, dscResposta] = elem;
      const verifyCampo = lista.campos.find(
        (valueList) => valueList.idCampo === idCampo
      );
      if (verifyCampo) {
        if (dscResposta instanceof Date) {
          verifyCampo.dscResposta = setFormatDate(dscResposta);
        }
        verifyCampo.dscResposta = dscResposta;
      }
    });

    return lista;
  }
};

export const selectSetFieldValue = (lista, values, form) => {
  if (lista.campos.length > 0) {
    Object.entries(values).forEach((elem) => {
      const [__, campoValue] = elem;
      const { idCampo } = campoValue;
      const verifyCampo = lista.campos.find(
        (valueList) => valueList.idCampo === idCampo
      );

      if (verifyCampo.dscResposta !== null) {
        form.setFieldValue(idCampo, verifyCampo.dscResposta);
      }
    });
  }
};

export const getGenerateDataForm = (lista) => {
  if (lista.length > 0) {
    let newObject = {};
    lista.map((elem) => {
      newObject[`${elem.idCampo}Form`] = {
        dominio: elem.dominio,
        idCampo: elem.idCampo,
        labelCampo: elem.labelCampo,
      };
    });
    return newObject;
  }
};

export const getGenerateValueForm = (lista) => {
  if (lista.length > 0) {
    let newObject = {};
    lista.map((elem) => {
      newObject[elem.idCampo] = {
        idCampo: elem.idCampo,
        dscResposta: elem.dscResposta,
      };
    });
    return newObject;
  }
};
