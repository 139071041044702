import React from "react";
import { Link } from "react-router-dom";
import styles from "./TitileBack.module.scss";
const TitileBack = ({ title, link }) => {
  return (
    <Link to={link} className={styles.title}>
      <i className="fa-solid fa-chevron-left"></i> {title}
    </Link>
  );
};

export default TitileBack;
