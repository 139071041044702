import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { setAutoFreeze } from "immer";
import vistoriaReducers from "./vistoria.store";
import agendamentoReducers from "./vistoria/agendamento.store";
import DominiosReducers from "./dominios.store";
import GlobalReducers from "./global.store";
import AjudaReducers from "./vistoria/ajuda.store";
import FormulariosReducers from "./formularios.store";
import SessionReducers from "./session.store";
import FormularioAgendamentoRealizadoReducers from "./vistoria/formularioAgendamentoRealizado.store";
import anexosReducers from "./vistoria/anexos.store";
import plantaReducers from "./vistoria/planta.store";
import usuariosReducers from "./administracao/usuarios.store";

const reducers = combineReducers({
  vistoria: vistoriaReducers,
  agendamento: agendamentoReducers,
  dominios: DominiosReducers,
  global: GlobalReducers,
  ajuda: AjudaReducers,
  formularios: FormulariosReducers,
  formularioAgendamentoRealizado: FormularioAgendamentoRealizadoReducers,
  anexos: anexosReducers,
  session: SessionReducers,
  usuarios: usuariosReducers,
  plantas: plantaReducers,
});

setAutoFreeze(false);
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["session", "vistoria"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

let persistor = persistStore(store);

export { store, persistor };
