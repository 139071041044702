import moment from "moment";

export const setFormatDate = (value, format = "YYYY-MM-DD") => {
  return value instanceof Date ? moment(value).format(format) : "";
};

export const getSelectFormatDate = (value, format) => {
  return moment(value).format(format);
};

export const getFormatDate = (value) => {
  return value ? moment(value).toDate() : "";
};

export const getValidDate = (value) => {
  return value instanceof Date;
};
