import { useDispatch } from "react-redux";
import { keycloak } from "../utils/auth";
import { setLogoutSession } from "../store/session.store";

const SessionActions = () => {
  const dispatch = useDispatch();
  const setLogout = async () => {
    dispatch(setLogoutSession());
    keycloak.logout();
  };

  return { setLogout };
};

export default SessionActions;
