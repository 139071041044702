import { Routes, Route } from "react-router-dom";
import { PrivateRouter } from "components";

const MapAllowedRoutes = ({ routes }) => {
  return (
    <Routes>
      {routes?.length > 0 &&
        routes.map((route) => {
          const { path, allowedroles, component: Component, ...rest } = route;

          return route?.private ? (
            <Route
              key={path}
              element={<PrivateRouter allowedroles={allowedroles} />}>
              <Route {...rest} path={path} element={<Component {...rest} />} />
            </Route>
          ) : (
            <Route
              {...rest}
              key={path}
              path={path}
              element={<Component {...rest} />}
            />
          );
        })}
    </Routes>
  );
};

export default MapAllowedRoutes;
