import { createSlice } from "@reduxjs/toolkit";
export const Agendamento = createSlice({
  name: "Agendamento",
  initialState: {
    loadingListAgendamento: false,
    loadingListAgendamentoFrustado: false,
    stateAgendamentoFrustado: false,
    stateAgendamentoRealizado: false,
    stateVisualizarAgendamento: false,
    stateDataAgendamento: false,

    listAgendamento: [],
    listMotivoAgendamentoFrustrado: null,
    agendamentoId: null,
    agendamento: null,
  },
  reducers: {
    setLoadingListAgendamentoStore: (state, action) => {
      state.loadingListAgendamento = action.payload;
    },
    setLoadingListAgendamentoFrustado: (state, action) => {
      state.loadingListAgendamentoFrustado = action.payload;
    },
    setModalAgendamentoRealizadoStore: (state, action) => {
      state.stateAgendamentoRealizado = action.payload;
    },

    setModalAgendamentoFrutadoStore: (state, action) => {
      state.stateAgendamentoFrustado = action.payload.state;
      state.agendamentoId = action.payload.agendamentoId;
    },
    setModalVisualizarAgendamentoStore: (state, action) => {
      state.stateVisualizarAgendamento = action.payload.state;
      state.agendamento = action.payload.agendamento;
    },
    setModalDataAgendamentoStore: (state, action) => {
      state.stateDataAgendamento = action.payload;
    },
    getListMotivoAgendamentoFrustradoStore: (state, action) => {
      state.listMotivoAgendamentoFrustrado = action.payload;
    },
    getListAgendamentoVistoriaStore: (state, action) => {
      state.listAgendamento = action.payload;
    },
    resetAgendamentoVistoriaStore: (state) => {
      state.loadingListAgendamento = false;
      state.loadingListAgendamentoFrustado = false;
      state.stateAgendamentoFrustado = false;
      state.stateAgendamentoRealizado = false;
      state.stateVisualizarAgendamento = false;
      state.stateDataAgendamento = false;
      state.listAgendamento = [];
      state.listMotivoAgendamentoFrustrado = null;
      state.agendamentoId = null;
      state.agendamento = null;
    },
    selectIdAgendamentoVistoriaStore: (state, action) => {
      state.agendamentoId = action.payload;
    },
  },
});

export const {
  setLoadingListAgendamentoStore,
  setLoadingListAgendamentoFrustado,
  setModalAgendamentoRealizadoStore,
  setModalAgendamentoFrutadoStore,
  setModalDataAgendamentoStore,

  setModalVisualizarAgendamentoStore,
  selectIdAgendamentoVistoriaStore,
  getListMotivoAgendamentoFrustradoStore,
  getListAgendamentoVistoriaStore,
  resetAgendamentoVistoriaStore,
} = Agendamento.actions;

export default Agendamento.reducer;
