import { useSelector } from "react-redux";

const GlobalHooks = () => {
  const { stateAlerts, message, severity, loadingPage } = useSelector(
    (state) => state.global
  );
  return {
    stateAlerts,
    message,
    severity,
    loadingPage,
  };
};

export default GlobalHooks;
