import { useState, useEffect } from "react";
import { configTipoUsuario, statusSistema } from "commons";
import { sessionsHooks } from "hooks";
import { useSelector } from "react-redux";

const AnexosHooks = () => {
  const { user_id, cod_TipoUsuario } = sessionsHooks();
  const { loadingAnexos, loadingFindAnexo, listAnexosVistoria, loadingNota } =
    useSelector((state) => state.anexos);

  const { vistoria } = useSelector((state) => state.vistoria);
  const [stateAbaVisibleAnexoPrestador, setStateAbaVisibleAnexoPrestador] =
    useState(false);
  const [stateFormAnexo, setStateFormAnexo] = useState(true);
  const [stateAbaAnexoSubscritor, setStateAbaAnexoSubscritor] = useState(false);
  const [stateActionFormAnexoPrestador, setStateActionFormAnexoPrestador] =
    useState(false);
  const [stateRemoverAnexo, setStateRemoverAnexo] = useState(true);

  useEffect(() => {
    const { subscritor, administrador, heade, prestador } = configTipoUsuario;
    const { codPrestador, codSituacaoVistoria } = vistoria;
    const {
      vistoriaLaudoEnviado,
      vistoriaRecusadaPrestador,
      vistoriaDispensada,
      vistoriaFinalizada,
      vistoriaCancelada,
      vistoriaAgendamentoRealizada,
    } = statusSistema;

    setStateRemoverAnexo(
      codSituacaoVistoria > vistoriaAgendamentoRealizada ? false : true
    );

    //Perfil prestador
    if (prestador === cod_TipoUsuario && codSituacaoVistoria !== undefined) {
      if (
        codSituacaoVistoria === vistoriaLaudoEnviado ||
        codSituacaoVistoria === vistoriaRecusadaPrestador ||
        codSituacaoVistoria === vistoriaDispensada ||
        codSituacaoVistoria === vistoriaFinalizada ||
        codSituacaoVistoria === vistoriaCancelada
      ) {
        setStateAbaVisibleAnexoPrestador(true);
      } else {
        setStateAbaVisibleAnexoPrestador(false);
      }

      if (
        codSituacaoVistoria === vistoriaRecusadaPrestador ||
        codSituacaoVistoria === vistoriaDispensada ||
        codSituacaoVistoria === vistoriaFinalizada ||
        codSituacaoVistoria === vistoriaCancelada
      ) {
        setStateFormAnexo(false);
      }

      //Se o codigo do usuario logado for igual ao do prestador realacionado com a vistoria
      if (user_id === codPrestador && codSituacaoVistoria !== undefined) {
        setStateActionFormAnexoPrestador(
          codSituacaoVistoria === vistoriaAgendamentoRealizada ? true : false
        );
      }
    }

    //Perfil subscritor
    if (
      subscritor === cod_TipoUsuario ||
      administrador === cod_TipoUsuario ||
      heade === cod_TipoUsuario
    ) {
      setStateAbaAnexoSubscritor(
        codSituacaoVistoria > vistoriaAgendamentoRealizada ? false : true
      );
    }
  }, [vistoria, configTipoUsuario]);

  return {
    loadingAnexos,
    loadingFindAnexo,
    loadingNota,
    stateRemoverAnexo,
    stateFormAnexo,
    stateAbaVisibleAnexoPrestador,
    stateAbaAnexoSubscritor,
    stateActionFormAnexoPrestador,
    listAnexosVistoria,
  };
};

export default AnexosHooks;
