export const configUrlsAmbiente = () => {
  const {
    NODE_ENV,
    REACT_APP_API_DEV_VISTORIA,
    REACT_APP_API_DEV_COTADOR,
    REACT_APP_API_DEV_USUARIO,
    REACT_APP_API_DEV_AUTH,
    REACT_APP_API_PROD_VISTORIA,
    REACT_APP_API_PROD_COTADOR,
    REACT_APP_API_PROD_USUARIO,
    REACT_APP_API_PROD_AUTH,
    REACT_APP_API_CEP,
    REACT_APP_REALM,
    REACT_APP_CLIENT_ID,
  } = process.env;

  let url = {
    REACT_APP_API_VISTORIA: "",
    REACT_APP_API_COTADOR: "",
    REACT_APP_API_CEP: "",
    REACT_APP_API_USUARIO: "",
    REACT_APP_API_AUTH: "",
    REACT_APP_REALM: "",
    REACT_APP_CLIENT_ID: "",
    AMBIENTE: "",
  };

  url.REACT_APP_REALM = REACT_APP_REALM;
  url.REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID;
  url.REACT_APP_API_CEP = REACT_APP_API_CEP;
  url.AMBIENTE = NODE_ENV;
  console.log(NODE_ENV);

  switch (NODE_ENV) {
    case "development":
      url.REACT_APP_API_VISTORIA = REACT_APP_API_DEV_VISTORIA;
      url.REACT_APP_API_COTADOR = REACT_APP_API_DEV_COTADOR;
      url.REACT_APP_API_USUARIO = REACT_APP_API_DEV_USUARIO;
      url.REACT_APP_API_AUTH = REACT_APP_API_DEV_AUTH;
      break;

    case "production":
      url.REACT_APP_API_VISTORIA = REACT_APP_API_PROD_VISTORIA;
      url.REACT_APP_API_COTADOR = REACT_APP_API_PROD_COTADOR;
      url.REACT_APP_API_USUARIO = REACT_APP_API_PROD_USUARIO;
      url.REACT_APP_API_AUTH = REACT_APP_API_PROD_AUTH;
      break;
  }

  return url;
};
