import { Suspense } from "react";
import { RoutesPages } from "./routes";
import MapAllowedRoutes from "./mapAllowedRoutes";

const Routes = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "95vh",
          }}>
          <p>Carregando...</p>
        </div>
      }>
      <MapAllowedRoutes routes={RoutesPages} />
    </Suspense>
  );
};

export default Routes;
