import { createSlice } from "@reduxjs/toolkit";

import { getGenerateDataForm } from "utils";
export const Planta = createSlice({
  name: "Planta",
  initialState: {
    loadingPlanta: false,
    loadingCrudsPlanta: false,
    stateModalPlanta: false,
    plantas: [],
    planta: { estrutura: {}, tipoConstrucao: [] },
    plantaForm: {},
  },
  reducers: {
    setloadingCrudsStore: (state, action) => {
      state.loadingCrudsPlanta = action.payload;
    },
    setLoadingPlantaStore: (state, action) => {
      state.loadingPlanta = action.payload;
    },
    setModalPlantaStore: (state, action) => {
      state.stateModalPlanta = action.payload;
    },
    setAddItemPlantaStore: (state, action) => {
      state.plantas = action.payload;
    },
    getFindPlantasStore: (state, action) => {
      state.plantas = action.payload.plantas;
    },
    getPlantaStore: (state, action) => {
      const { estruturas, tipoConstrucao } = action.payload;
      let newEstruturas = {};
      let newTipoConstrucao = [];
      if (estruturas !== undefined && Object.values(estruturas).length > 0) {
        newEstruturas = estruturas;
      }

      if (tipoConstrucao !== undefined && tipoConstrucao.length > 0) {
        newTipoConstrucao = tipoConstrucao;
      }

      state.planta = {
        estruturas: newEstruturas,
        tipoConstrucao: newTipoConstrucao,
      };
    },
    getDataFormPlantaStore: (state, action) => {
      state.plantaForm = action.payload;
    },
  },
});

export const {
  getFindPlantasStore,
  getPlantaStore,
  setLoadingPlantaStore,
  setloadingCrudsStore,
  setModalPlantaStore,
  getDataFormPlantaStore,
} = Planta.actions;

export default Planta.reducer;
