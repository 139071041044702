import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { configTipoUsuario, statusSistema } from "commons";
import { sessionsHooks } from "hooks";

const VistoriaHooks = () => {
  const {
    loadingDetalheVistoria,
    loadingHistoricoVistoria,
    loadingListAgendamento,
    loadingRecusarVistoria,
    loadingEscolherOutroVistoriador,
    loadingCancelarVistoria,
    loadingDataListVistoria,
    stateCancelarVistoria,
    stateEscolherOutroVistoriador,
    stateAgendamentoFrustado,
    stateRecusarVistoria,
    stateAgendamentoRealizado,
    stateAgendamentoRealizadoVistoriador,
    stateDataAgendamento,
    stateHistoricoVistoria,
    listPrestador,
    listVistoria,
    listHistoricoVistoria,
    listAgendamento,
    tabMenuDetalheVistoria,
    codSituacaoAgendamento,
    agendamentoId,
    vistoria,
    vistoriaId,
  } = useSelector((state) => state.vistoria);

  const { user_id, cod_TipoUsuario } = sessionsHooks();
  const [checkUserTipoUsuarioSubscritor, setCheckUserTipoUsuarioSubscritor] =
    useState(false);
  const [checkUserTipoUsuarioPrestador, setCheckUserTipoUsuarioPrestador] =
    useState(false);
  const [codigoStateVistoria, setCodigoStateVistoria] = useState(0);
  const [stateVistoria, setStateVistoria] = useState(true);
  const [stateActionVitoria, setStateActionVitoria] = useState(true);
  const [visibleFormKm, setVisibleFormKm] = useState(true);

  const [stateCodSituacaoVistoria, setStateCodSituacaoVistoria] = useState(0);

  useEffect(() => {
    const { prestador, subscritor, administrador, heade } = configTipoUsuario;

    const {
      vistoriaAceitaPrestador,
      vistoriaLaudoEnviado,
      vistoriaRecusadaPrestador,
      vistoriaDispensada,
      vistoriaFinalizada,
      vistoriaCancelada,
      vistoriaSolicitada,
    } = statusSistema;

    const { codPrestador, codSituacaoVistoria } = vistoria;

    if (cod_TipoUsuario !== undefined) {
      setCheckUserTipoUsuarioSubscritor(
        subscritor === cod_TipoUsuario ||
          administrador === cod_TipoUsuario ||
          heade === cod_TipoUsuario
          ? true
          : false
      );
      setCheckUserTipoUsuarioPrestador(
        prestador === cod_TipoUsuario ? true : false
      );
    }

    setCodigoStateVistoria(codSituacaoVistoria > 0 ? codSituacaoVistoria : 0);

    setStateVistoria(
      codSituacaoVistoria === vistoriaSolicitada ||
        codSituacaoVistoria === vistoriaRecusadaPrestador
        ? false
        : true
    );

    if (
      codSituacaoVistoria === vistoriaRecusadaPrestador ||
      codSituacaoVistoria === vistoriaDispensada ||
      codSituacaoVistoria === vistoriaFinalizada ||
      codSituacaoVistoria === vistoriaCancelada
    ) {
      setStateActionVitoria(false);
    } else {
      setStateActionVitoria(true);
    }

    //Perfil prestador
    if (prestador === cod_TipoUsuario && codSituacaoVistoria !== undefined) {
      setVisibleFormKm(
        codSituacaoVistoria === vistoriaAceitaPrestador ? false : true
      );

      //Se o codigo do usuario logado for igual ao do prestador realacionado com a vistoria
      if (user_id === codPrestador && codSituacaoVistoria !== undefined) {
        setStateCodSituacaoVistoria(
          codSituacaoVistoria !== vistoriaLaudoEnviado ? codSituacaoVistoria : 0
        );
      }
    }

    //Perfil subscritor
    if (
      subscritor === cod_TipoUsuario ||
      administrador === cod_TipoUsuario ||
      heade === cod_TipoUsuario
    ) {
      setStateCodSituacaoVistoria(
        codSituacaoVistoria === vistoriaLaudoEnviado ? codSituacaoVistoria : 0
      );
    }
  }, [configTipoUsuario, cod_TipoUsuario, user_id, vistoria]);

  return {
    loadingDetalheVistoria,
    loadingHistoricoVistoria,
    loadingListAgendamento,
    loadingRecusarVistoria,
    loadingEscolherOutroVistoriador,
    loadingCancelarVistoria,
    loadingDataListVistoria,
    stateEscolherOutroVistoriador,
    stateCancelarVistoria,
    stateAgendamentoFrustado,
    stateRecusarVistoria,
    stateAgendamentoRealizado,
    stateAgendamentoRealizadoVistoriador,
    stateHistoricoVistoria,
    stateDataAgendamento,
    stateVistoria,
    stateActionVitoria,
    stateCodSituacaoVistoria,
    listPrestador,
    listVistoria,
    listHistoricoVistoria,
    listAgendamento,
    vistoriaId,
    agendamentoId,
    codSituacaoAgendamento,
    vistoria,
    visibleFormKm,
    tabMenuDetalheVistoria,
    codigoStateVistoria,
    checkUserTipoUsuarioPrestador,
    checkUserTipoUsuarioSubscritor,
  };
};

export default VistoriaHooks;
