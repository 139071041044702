import { ContainerFormTemplate } from "ebix-componentes-react";
import { ScrollTop } from "primereact/scrolltop";

const Page = (props) => {
  const { children, ...res } = props;

  return (
    <>
      <ContainerFormTemplate {...res}>{children}</ContainerFormTemplate>
      <ScrollTop
        target="parent"
        threshold={100}
        className="custom-scrolltop"
        icon="pi pi-arrow-up"
      />
    </>
  );
};

export default Page;
