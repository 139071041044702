import { useDispatch } from "react-redux";
import {
  setLoadingAnexosStore,
  setLoadingFindAnexoStore,
  setLoadingNotaStore,
  getListAnexosStore,
  setUpdateNotaStore,
  resetAnexosStore,
} from "../../store/vistoria/anexos.store";
import { useParams } from "react-router-dom";
import { downloadBase64File, requests } from "utils";
import { sessionsHooks } from "hooks";

const Anexos = () => {
  const dispatch = useDispatch();
  const { user_id } = sessionsHooks();
  const { setPutRequest, findGetRequest, setDeleteRequest } = requests();
  const { vistoria_id } = useParams();

  const getListAnexosVistoriaAction = async () => {
    dispatch(resetAnexosStore());
    dispatch(setLoadingAnexosStore(true));
    const response = await findGetRequest({
      rota: `/vistoria/${vistoria_id}/arquivos`,
    });

    if (response) {
      dispatch(getListAnexosStore(response.data.arquivos));
    }
    dispatch(setLoadingAnexosStore(false));
  };

  const getFindItemAnexosVistoriaAction = async (idArquivo) => {
    dispatch(setLoadingFindAnexoStore(true));
    await findGetRequest({
      rota: `/vistoria/${vistoria_id}/buscarArquvio/${idArquivo}`,
    })
      .then((response) => {
        const { nmeArquivo, conteudoArquivo } = response.data;
        return downloadBase64File(null, conteudoArquivo, nmeArquivo);
      })
      .finally(() => {
        dispatch(setLoadingFindAnexoStore(false));
      });
  };

  const setUpdateStateFiles = async (dados) => {
    dados.codUsuarioLogado = user_id;
    dispatch(setLoadingAnexosStore(true));
    await setPutRequest({
      rota: `vistoria/${vistoria_id}/statusArquivo/${dados.arquivoId}`,
      params: dados,
      message: "STATUS ATUALIZADO COM SUCESSO!",
    });
    getListAnexosVistoriaAction();
  };

  const setUpdateNotaFile = async (dados) => {
    dados.codUsuarioLogado = user_id;
    dispatch(setLoadingNotaStore(true));
    const response = await setPutRequest({
      rota: `vistoria/${vistoria_id}/notaArquivo/${dados.arquivoId}`,
      params: dados,
      message: "NOTA ADICIONADA COM SUCESSO!",
    });
    dispatch(setUpdateNotaStore(response.data));
    dispatch(setLoadingNotaStore(false));
  };

  const setRemoveFilesVistoria = async (arquivoId) => {
    dispatch(setLoadingAnexosStore(true));
    await setDeleteRequest({
      rota: `vistoria/${vistoria_id}/excluirArquivo/${arquivoId}`,
      message: "ARQUIVO REMOVIDO COM SUCESSO!",
    });
    getListAnexosVistoriaAction();
  };

  return {
    setUpdateStateFiles,
    setRemoveFilesVistoria,
    setUpdateNotaFile,
    getListAnexosVistoriaAction,
    getFindItemAnexosVistoriaAction,
  };
};

export default Anexos;
