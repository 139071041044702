import { lazy } from "react";
import { configRoutes } from "commons";

const VistoriaConsulta = lazy(() =>
  import("../view/Vistoria/pages/vistoriaConsulta")
);
const VistoriaManual = lazy(() =>
  import("../view/Vistoria/pages/vistoriaManual")
);
const VistoriaDetalhe = lazy(() =>
  import("../view/Vistoria/pages/vistoriaDetalhe")
);
const VistoriaRelatorio = lazy(() =>
  import("../view/Vistoria/pages/vistoriaRelatorio")
);
const VistoriaAgendamentoRealizado = lazy(() =>
  import("../view/Vistoria/pages/vistoriaAgendamentoRealizado")
);
const VistoriaAgendamentoRealizadoVerFormulario = lazy(() =>
  import("../view/Vistoria/pages/vistoriaAgendamentoRealizadoVerFormulario")
);
const VistoriaAnexos = lazy(() =>
  import("../view/Vistoria/pages/vistoriaAnexos")
);
const UsuarioConsulta = lazy(() =>
  import("../view/administracao/usuarios/pages/usuarioConsulta")
);
const UsuarioCadastrar = lazy(() =>
  import("../view/administracao/usuarios/pages/usuarioCadastrar")
);
const UsuarioEditar = lazy(() =>
  import("../view/administracao/usuarios/pages/usuarioEditar")
);
const UnauthorizedNotFound = lazy(() => import("../view/UnauthorizedNotFound"));

const { vistoria, usuarios } = configRoutes;

export const RoutesPages = [
  {
    component: UnauthorizedNotFound,
    path: "/cotador",
    title: "Carregando...",
    private: true,
    visibleButtom: false,
    allowedroles: vistoria.consultar.permissao,
  },
  {
    component: VistoriaConsulta,
    path: "/",
    title: "",
    private: true,
    allowedroles: vistoria.consultar.permissao,
  },
  {
    component: VistoriaConsulta,
    path: vistoria.consultar.rota,
    title: "",
    private: true,
    allowedroles: vistoria.consultar.permissao,
  },
  {
    component: VistoriaManual,
    path: vistoria.cadastrar.rota,
    title: "",
    private: true,
    allowedroles: vistoria.cadastrar.permissao,
    exact: true,
  },
  {
    component: VistoriaDetalhe,
    path: `${vistoria.detalhe.rota}/:vistoria_id`,
    title: "",
    private: true,
    allowedroles: vistoria.detalhe.permissao,
    exact: true,
  },
  {
    component: VistoriaAgendamentoRealizado,
    path: `${vistoria.agendamentorealizado.rota}/:vistoria_id`,
    title: "",
    private: true,
    allowedroles: vistoria.agendamentorealizado.permissao,
    exact: true,
  },
  {
    component: VistoriaAgendamentoRealizadoVerFormulario,
    path: `${vistoria.agendamentorealizadoVerFormulario.rota}/:vistoria_id`,
    allowedroles: vistoria.agendamentorealizadoVerFormulario.permissao,
    title: "",
    private: true,
  },
  {
    component: VistoriaAnexos,
    path: `${vistoria.anexos.rota}/:vistoria_id`,
    allowedroles: vistoria.anexos.permissao,
    title: "",
    private: true,
  },
  {
    component: VistoriaRelatorio,
    path: vistoria.relatorio.rota,
    allowedroles: vistoria.relatorio.permissao,
    title: "",
    private: true,
  },

  {
    component: UsuarioConsulta,
    path: usuarios.consultar.rota,
    allowedroles: usuarios.consultar.permissao,
    title: "",
    private: true,
    exact: true,
  },
  {
    component: UsuarioCadastrar,
    path: usuarios.cadastrar.rota,
    allowedroles: usuarios.cadastrar.permissao,
    title: "",
    private: true,
    exact: true,
  },
  {
    component: UsuarioEditar,
    path: `${usuarios.editar.rota}/:usuario_id`,
    allowedroles: usuarios.editar.permissao,
    title: "",
    private: true,
  },
  {
    component: UnauthorizedNotFound,
    path: "*",
    title: "PÁGINA NÃO ENCONTRADA!",
    icon: "fa-solid fa-triangle-exclamation",
  },
  {
    component: UnauthorizedNotFound,
    path: "/Unauthorized",
    title: "ACESSO NÃO AUTORIZADO!",
    icon: "fa-solid fa-lock",
  },
  {
    component: UnauthorizedNotFound,
    path: "/blockAcess",
    title: "ACESSO BLOQUEADO!",
    icon: "fa-solid fa-user-lock",
  },
];

export default {
  RoutesPages,
};
