import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { keycloak } from "../../utils/auth";
import { setSession } from "../../store/session.store";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { vistoriaAction } from "actions";
import { configUrlsAmbiente } from "../../api/configUrlsAmbiente";

const PrivateRouter = ({ allowedroles }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { setRouteCotador } = vistoriaAction();
  const configUrl = configUrlsAmbiente();

  useEffect(() => {
    if (location.pathname === "/cotador") {
      setRouteCotador(`${configUrl.REACT_APP_API_COTADOR}/acessoapp`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    keycloak.updateToken();

    if (keycloak.authenticated) {
      const { preferred_username, user_id, cod_TipoUsuario, flag_ativo } =
        keycloak.tokenParsed;

      dispatch(
        setSession({
          nomeUsuario: preferred_username,
          user_id: user_id,
          cod_TipoUsuario: cod_TipoUsuario,
          flag_ativo: flag_ativo,
        })
      );

      // if (flag_ativo !== "S") {
      //   return navigate("/blockAcess");
      // }
    }

    if (
      !allowedroles.includes(parseInt(keycloak.tokenParsed.cod_TipoUsuario))
    ) {
      return navigate("/Unauthorized");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !keycloak.authenticated ? keycloak?.login() : <Outlet />;
};

export default PrivateRouter;
