import { useSelector } from "react-redux";

const PlantasHooks = () => {
  const {
    loadingPlanta,
    loadingCrudsPlanta,
    stateModalPlanta,
    planta,
    plantas,
    plantaForm,
  } = useSelector((state) => state.plantas);

  return {
    loadingPlanta,
    loadingCrudsPlanta,
    stateModalPlanta,
    planta,
    plantas,
    plantaForm,
  };
};

export default PlantasHooks;
