import { MenuVertical } from "ebix-componentes-react";
import { sessionAction } from "actions";
import { menuList } from "commons";
import { sessionsHooks } from "hooks";
import excelsiorseg from "../../assets/img/excelsiorseg.png";
import excelsiorsegMobile from "../../assets/img/excelsiorsegMobile.png";

const NavBar = () => {
  const { setLogout } = sessionAction();
  const { cod_TipoUsuario, nomeUsuario } = sessionsHooks();

  const perfil = cod_TipoUsuario;

  return (
    <nav>
      <MenuVertical
        logout={setLogout}
        menuVertical={menuList()}
        perfil={perfil}
        nomeUsuario={nomeUsuario}
        logoLarge={excelsiorseg}
        logoSmall={excelsiorsegMobile}
      />
    </nav>
  );
};

export default NavBar;
