import { ApiVistoria } from "api";
import { useDispatch } from "react-redux";
import { setStateAlerts } from "../store/global.store";

const Requests = () => {
  const dispatch = useDispatch();
  const setAlertRequest = (data) => {
    const { code, message } = data;

    dispatch(
      setStateAlerts({ stateAlerts: true, code: code, message: message })
    );
    setTimeout(() => {
      dispatch(
        setStateAlerts({ stateAlerts: false, code: null, message: null })
      );
    }, 2500);
  };

  const findGetRequest = async ({
    rota,
    params = null,
    header = null,
    message = null,
    Api = null,
  }) => {
    let getApi = Api !== null ? Api : ApiVistoria;
    return await getApi
      .get(rota, { params }, header)
      .then((response) => {
        let { status } = response;
        setAlertRequest({ code: status, message: message });
        return response;
      })
      .catch((error) => {
        const response = error.response.data;
        let { status } = error.response;
        let { titulo } = response;

        setAlertRequest({
          code: status,
          message: titulo !== undefined ? titulo : error.message,
        });
      });
  };

  const setPostRequest = async ({
    rota,
    params,
    header = null,
    message = null,
    Api = null,
  }) => {
    let setApi = Api !== null ? Api : ApiVistoria;
    return await setApi
      .post(rota, params, header)
      .then((response) => {
        let { status } = response;
        setAlertRequest({ code: status, message: message });
        return response;
      })
      .catch((error) => {
        const response = error.response.data;
        let { status } = error.response;
        let { titulo } = response;

        setAlertRequest({
          code: status,
          message: titulo !== undefined ? titulo : error.message,
        });
      });
  };

  const setPutRequest = async ({
    rota,
    params,
    header = null,
    message = null,
    Api = null,
  }) => {
    let setApi = Api !== null ? Api : ApiVistoria;
    return await setApi
      .put(rota, params, header)
      .then((response) => {
        let { status } = response;
        setAlertRequest({ code: status, message: message });
        return response;
      })
      .catch((error) => {
        const response = error.response.data;
        let { status } = error.response;
        let { titulo } = response;

        setAlertRequest({
          code: status,
          message: titulo !== undefined ? titulo : error.message,
        });
      });
  };

  const setDeleteRequest = async ({
    rota,
    header = null,
    message = null,
    Api = null,
  }) => {
    let setApi = Api !== null ? Api : ApiVistoria;
    return await setApi
      .delete(rota, header)
      .then((response) => {
        let { status } = response;
        setAlertRequest({ code: status, message: message });
        return response;
      })
      .catch((error) => {
        const response = error.response.data;
        let { status } = error.response;
        let { titulo } = response;

        setAlertRequest({
          code: status,
          message: titulo !== undefined ? titulo : error.message,
        });
      });
  };

  return {
    findGetRequest,
    setPostRequest,
    setPutRequest,
    setDeleteRequest,
    setAlertRequest,
  };
};

export default Requests;
