import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getFindPlantasStore,
  getPlantaStore,
  setLoadingPlantaStore,
  setloadingCrudsStore,
  setModalPlantaStore,
  getDataFormPlantaStore,
} from "../../store/vistoria/planta.store";

import { plantasHooks, sessionsHooks } from "hooks";
import { requests } from "utils";

const VistoriaAction = () => {
  const dispatch = useDispatch();

  const { user_id } = sessionsHooks();
  const { vistoria_id } = useParams();
  const { setPutRequest, findGetRequest, setDeleteRequest } = requests();
  const { stateModalPlanta } = plantasHooks();

  const setModalPlantaAction = async () => {
    dispatch(setModalPlantaStore(!stateModalPlanta));
    dispatch(getDataFormPlantaStore({}));
  };

  const setCadastroPlantaAction = async (dados) => {
    dados.codUsuarioLogado = user_id;

    dispatch(setloadingCrudsStore(true));
    const response = await setPutRequest({
      rota: `vistoria/${vistoria_id}/formulario/planta`,
      params: dados,
      message: "CADASTRO REALIZADO COM SUCESSO!",
    });

    if (response) {
      getFindPlantasAction();
    }
    dispatch(setloadingCrudsStore(false));
    return response;
  };

  const setEditarPlantaAction = async (dados) => {
    dados.codUsuarioLogado = user_id;

    dispatch(setloadingCrudsStore(true));
    const response = await setPutRequest({
      rota: `vistoria/${vistoria_id}/formulario/editarPlanta/${dados.idPlanta}`,
      params: dados,
      message: "EDIÇÃO REALIZADA COM SUCESSO!",
    });

    if (response) {
      getFindPlantasAction();
    }
    dispatch(setloadingCrudsStore(false));
    return response;
  };

  const getFindPlantaAction = async (idPlanta) => {
    setModalPlantaAction();
    dispatch(setloadingCrudsStore(true));

    const response = await findGetRequest({
      rota: `vistoria/${vistoria_id}/formulario/planta/${idPlanta}`,
    });

    if (response) {
      dispatch(getDataFormPlantaStore(response.data));
    }
    dispatch(setloadingCrudsStore(false));
  };

  const getFindPlantasAction = async () => {
    dispatch(setLoadingPlantaStore(true));
    const response = await findGetRequest({
      rota: `vistoria/${vistoria_id}/formulario/1/plantas`,
    });

    if (response) {
      dispatch(getFindPlantasStore(response.data));
    }
    dispatch(setLoadingPlantaStore(false));
  };

  const removePlantasAction = async (id) => {
    dispatch(setLoadingPlantaStore(true));
    const response = await setDeleteRequest({
      rota: `vistoria/${vistoria_id}/formulario/excluirPlanta/${id}`,
      message: "PLANTA REMOVIDA COM SUCESSO!",
    });

    if (response) {
      getFindPlantasAction();
    }

    dispatch(setLoadingPlantaStore(false));
  };

  const findFormulariosPlantaAction = async () => {
    const response = await findGetRequest({
      rota: `formularios/1/planta`,
    });

    if (response) {
      dispatch(getPlantaStore(response.data));
    }
  };

  return {
    setCadastroPlantaAction,
    getFindPlantasAction,
    removePlantasAction,
    findFormulariosPlantaAction,
    getFindPlantaAction,
    setModalPlantaAction,
    setEditarPlantaAction,
  };
};

export default VistoriaAction;
