import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setLoadingListAgendamentoStore,
  setLoadingListAgendamentoFrustado,
  setModalDataAgendamentoStore,
  setModalAgendamentoFrutadoStore,
  setModalAgendamentoRealizadoStore,
  setModalVisualizarAgendamentoStore,
  getListMotivoAgendamentoFrustradoStore,
  getListAgendamentoVistoriaStore,
  resetAgendamentoVistoriaStore,
  selectIdAgendamentoVistoriaStore,
} from "../../store/vistoria/agendamento.store";
import {
  incrementStepsVistoriaStore,
  decrementStepsVistoriaStore,
} from "../../store/formularios.store";
import { setloadingPageStore } from "../../store/global.store";
import { requests } from "utils";
import { sessionsHooks, agendamentoHooks } from "hooks";
import { vistoriaAction } from "actions";

const AgendamentoAction = () => {
  const dispatch = useDispatch();
  const { user_id } = sessionsHooks();
  const { getDetalheVistoriaAction } = vistoriaAction();

  const {
    stateAgendamentoFrustado,
    stateDataAgendamento,
    stateAgendamentoRealizado,
    stateVisualizarAgendamento,
  } = agendamentoHooks();
  const { vistoria_id } = useParams();
  const { setPostRequest, setPutRequest, findGetRequest } = requests();

  const setModalDataAgendamentoAction = () => {
    dispatch(setModalDataAgendamentoStore(!stateDataAgendamento));
  };

  const setModalVisualizarAgendamentoAction = (data = null) => {
    dispatch(
      setModalVisualizarAgendamentoStore({
        state: !stateVisualizarAgendamento,
        agendamento: data ? data : null,
      })
    );
  };

  const setModalAgendamentoFrustadoAction = (data = null) => {
    dispatch(
      setModalAgendamentoFrutadoStore({
        state: !stateAgendamentoFrustado,
        agendamentoId: data ? data.agendamentoId : null,
        codSituacaoAgendamento: data ? data.codSituacaoAgendamento : null,
      })
    );
  };

  const setModalAgendamentoRealizadoAction = () => {
    dispatch(setModalAgendamentoRealizadoStore(!stateAgendamentoRealizado));
  };

  const incrementStepsVistoriaAction = () => {
    dispatch(incrementStepsVistoriaStore());
  };

  const decrementStepsVistoriaAction = () => {
    dispatch(decrementStepsVistoriaStore());
  };

  const getListAgendamentosAction = async () => {
    dispatch(resetAgendamentoVistoriaStore());
    dispatch(setLoadingListAgendamentoStore(true));

    await findGetRequest({ rota: `vistoria/${vistoria_id}/agendamentos` }).then(
      (response) => {
        return dispatch(
          getListAgendamentoVistoriaStore(response.data.agendamentos)
        );
      }
    );
    dispatch(setLoadingListAgendamentoStore(false));
  };

  const getListMotivoAgendamentoFrustradoAction = async () => {
    dispatch(setLoadingListAgendamentoFrustado(true));
    const response = await findGetRequest({
      rota: "dominios/IComboMotivoAgendamentoFrustrado",
    });
    if (response) {
      dispatch(getListMotivoAgendamentoFrustradoStore(response.data.dominio));
    }

    dispatch(setLoadingListAgendamentoFrustado(false));
  };

  const setNewAgendamentoAction = async (dados) => {
    let newData = {
      codUsuarioLogado: user_id,
      dataAgendamento: dados.dataAgendamento,
      horaAgendamento: dados.horaAgendamento,
      dscObsAgendamento: dados.dscObsAgendamento,
    };

    dispatch(setloadingPageStore(true));
    const response = await setPostRequest({
      rota: `vistoria/${vistoria_id}/agendar`,
      params: newData,
      message: "AGENDAMENTO REALIZADO COM SUCESSO!",
    });

    if (response) {
      setModalDataAgendamentoAction();
      getDetalheVistoriaAction(vistoria_id);
    }

    dispatch(setloadingPageStore(false));
    return response;
  };

  const setChangeStateAgendamentoAction = async (dados) => {
    dispatch(setloadingPageStore(true));
    dados.codUsuarioLogado = user_id;
    const response = await setPutRequest({
      rota: `vistoria/${vistoria_id}/agendamento/${dados.agendamentoId}`,
      params: dados,
      message: dados.message,
    });
    if (response) {
      getDetalheVistoriaAction(vistoria_id);
    }
    dispatch(setloadingPageStore(false));
    return response;
  };

  const selectIdAgendamentoVistoriaAction = async (agendamentoId) => {
    dispatch(selectIdAgendamentoVistoriaStore(agendamentoId));
  };

  return {
    setNewAgendamentoAction,
    setChangeStateAgendamentoAction,
    setModalDataAgendamentoAction,
    setModalAgendamentoFrustadoAction,
    setModalAgendamentoRealizadoAction,
    selectIdAgendamentoVistoriaAction,
    setModalVisualizarAgendamentoAction,
    getListAgendamentosAction,
    getListMotivoAgendamentoFrustradoAction,
    incrementStepsVistoriaAction,
    decrementStepsVistoriaAction,
  };
};

export default AgendamentoAction;
