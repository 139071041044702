import { useKeycloak } from "@react-keycloak/web";
import { ToastCustom } from "ebix-componentes-react";
import { BrowserRouter as Router } from "react-router-dom";
import { NavBar, Footer } from "components";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { globalHooks } from "hooks";
import Routes from "./routes";

export const AppRouter = () => {
  const { initialized } = useKeycloak();
  const { stateAlerts, severity, message, loadingPage } = globalHooks();

  if (!initialized) {
    return <div>Carregando...</div>;
  }

  return (
    <Router>
      <NavBar />
      <BlockUI
        blocked={loadingPage}
        template={<ProgressSpinner />}
        style={{ position: "fixed" }}>
        <ToastCustom
          severity={severity}
          state={stateAlerts}
          message={message}
          style={{ zIndex: 70000 }}
        />

        <article>
          <Routes />
        </article>
        <Footer />
      </BlockUI>
    </Router>
  );
};
