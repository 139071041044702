import { createSlice, current } from "@reduxjs/toolkit";
export const Anexos = createSlice({
  name: "Anexos",
  initialState: {
    loadingAnexos: false,
    loadingNota: false,
    loadingFindAnexo: false,
    listAnexosVistoria: [],
  },
  reducers: {
    setLoadingAnexosStore: (state, action) => {
      state.loadingAnexos = action.payload;
    },
    setLoadingNotaStore: (state, action) => {
      state.loadingNota = action.payload;
    },
    setLoadingFindAnexoStore: (state, action) => {
      state.loadingFindAnexo = action.payload;
    },
    getListAnexosStore: (state, action) => {
      state.listAnexosVistoria = action.payload;
    },
    setUpdateNotaStore: (state, action) => {
      const dadosAnexos = current(state.listAnexosVistoria);
      const dados = action.payload;
      let newListAnexo = dadosAnexos.find((elem) => elem.id === dados.id);
      newListAnexo.dscNotaArquivo = dados.dscNotaArquivo;
      state.listAnexosVistoria = dadosAnexos;
    },
    resetAnexosStore: (state) => {
      state.loadingAnexos = false;
      state.loadingNota = false;
      state.listAnexosVistoria = [];
    },
  },
});

export const {
  setLoadingAnexosStore,
  setLoadingFindAnexoStore,
  getListAnexosStore,
  setLoadingNotaStore,
  setUpdateNotaStore,
  resetAnexosStore,
} = Anexos.actions;

export default Anexos.reducer;
