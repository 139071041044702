import { requests } from "utils";
import { ApiUsuarios } from "api";
import { useDispatch } from "react-redux";
import { setloadingPageStore } from "../../store/global.store";
import {
  getListUsuarioStore,
  getDataUsuarioStore,
} from "../../store/administracao/usuarios.store";
import { sessionsHooks } from "hooks";
import { configTipoUsuario } from "commons";

const UsuariosActions = () => {
  const dispatch = useDispatch();
  const { user_id } = sessionsHooks();
  const { setPostRequest, setPutRequest, findGetRequest } = requests();

  const getByUsuariosAction = async (dados, pagina) => {
    dispatch(setloadingPageStore(true));

    let newData = {
      cdUsuarioLogado: String(user_id),
      cdTipoUsuario: parseInt(configTipoUsuario.prestador),
      cdGrupo: dados.cdGrupo,
      dsNome: dados.dsNome,
      dsApelido: dados.dsApelido,
      flAtivo: dados.flAtivo,
      cdPerfil: parseInt(dados.cdPerfil),
    };

    const response = await setPostRequest({
      rota: `usuarios/listar?pagina=${pagina}`,
      params: newData,
      Api: ApiUsuarios,
    });

    if (response) {
      const { tamanho, conteudo, numeroPagina, totalElementos } = response.data;

      dados.numeroLinhasPorPagina = tamanho;
      dados.numeroPagina = numeroPagina;
      dados.pagina = numeroPagina;
      dados.totalElementos = totalElementos;

      dispatch(
        getListUsuarioStore({
          listUsuarios: conteudo,
          loadingDataListUsuarios: dados,
        })
      );
    }

    dispatch(setloadingPageStore(false));
  };

  const getByUsuarioAction = async (usuario_id) => {
    dispatch(setloadingPageStore(true));

    const response = await findGetRequest({
      rota: `usuarios/${usuario_id}`,
      Api: ApiUsuarios,
    });

    if (response) {
      dispatch(getDataUsuarioStore(response.data));
    }

    dispatch(setloadingPageStore(false));
  };

  const setCadastrarUsuarioActon = async (dados) => {
    dispatch(setloadingPageStore(true));
    const response = await setPostRequest({
      rota: "usuarios",
      params: dados,
      Api: ApiUsuarios,
      message: "USUÁRIO CADASTRADO COM SUCESSO!",
    });
    dispatch(setloadingPageStore(false));
    return response;
  };

  const setEditarUsuarioActon = async (dados, cdUsuario) => {
    dispatch(setloadingPageStore(true));

    const response = await setPutRequest({
      rota: `usuarios/${cdUsuario}`,
      params: dados,
      Api: ApiUsuarios,
      message: "USUÁRIO EDITADO COM SUCESSO!",
    });

    dispatch(setloadingPageStore(false));
    return response;
  };

  return {
    getByUsuariosAction,
    getByUsuarioAction,
    setCadastrarUsuarioActon,
    setEditarUsuarioActon,
  };
};

export default UsuariosActions;
