import Keycloak from "keycloak-js";
import { configUrlsAmbiente } from "../api/configUrlsAmbiente";
const configUrl = configUrlsAmbiente();
const dados = {
  url: configUrl.REACT_APP_API_AUTH,
  realm: configUrl.REACT_APP_REALM,
  clientId: configUrl.REACT_APP_CLIENT_ID,
  "public-client": true,
  "confidential-port": 0,
  "ssl-required": "external",
};
export const keycloak = new Keycloak(dados);

//login-required | check-sso
export const keycloakProviderInitConfig = {
  onLoad: "check-sso",
  // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  // pkceMethod: "S256",
  checkLoginIframe: false,
};
