import { useDispatch } from "react-redux";
import {
  setLoadinAjudaStore,
  setLoadinListAjudaStore,
  getListHistoriAjudaVistoriaStore,
  setResetListaAjudaVistoria,
} from "../../store/vistoria/ajuda.store";
import { useParams } from "react-router-dom";
import { requests } from "utils";
import { sessionsHooks } from "hooks";

const AjudaAction = () => {
  const dispatch = useDispatch();
  const { user_id } = sessionsHooks();
  const { vistoria_id } = useParams();
  const { setPostRequest, findGetRequest } = requests();

  const gettSolicitarHistoricoAjudaVistoriaAction = async () => {
    dispatch(setResetListaAjudaVistoria());
    dispatch(setLoadinListAjudaStore(true));

    const response = await findGetRequest({
      rota: `vistoria/${vistoria_id}/ajuda`,
    });
    dispatch(getListHistoriAjudaVistoriaStore(response.data));
    dispatch(setLoadinListAjudaStore(false));
    return response;
  };

  const setSolicitarHistoricoAjudaVistoriaAction = async (dados) => {
    dispatch(setLoadinAjudaStore(true));
    dados.codUsuarioLogado = user_id;
    await setPostRequest({
      rota: `vistoria/${vistoria_id}/ajuda`,
      params: dados,
      message: "AÇÃO REALIZADA COM SUCESSO!",
    });
    gettSolicitarHistoricoAjudaVistoriaAction();
    dispatch(setLoadinAjudaStore(false));
  };

  return {
    setSolicitarHistoricoAjudaVistoriaAction,
    gettSolicitarHistoricoAjudaVistoriaAction,
  };
};

export default AjudaAction;
